import React from "react";
import styled from "styled-components";
import { Link, useStaticQuery, graphql } from "gatsby";
import cornerLeft from "../assets/corner-bot-left-properties.svg";
import GoogleMaps from "./GoogleMaps";

const PropertiesHomeSectionStyles = styled.section`
  position: relative;
  grid-column: 1 / span 14;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 5.2rem)) 1fr;
  gap: 0 2rem;
  margin: 0;
  padding: 4em 0;
  background: var(--color-black);

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 1 / span 8;
    grid-template-columns: 2rem repeat(6, 1fr) 2rem;
    gap: 0 1rem;
    margin: 0;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    grid-template-columns: 1rem repeat(6, 1fr) 1rem;
    gap: 0 1rem;
    margin: 0;
  }

  .text-left {
    grid-column: 2 / span 6;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    @media ${(props) => props.theme.breakpoints.m} {
      text-align: center;
      align-items: center;
      margin-bottom: 2em;
    }
    h1,
    h2,
    p {
      color: var(--color-white);
      padding-right: 4em;
      @media ${(props) => props.theme.breakpoints.m} {
        padding-right: 0;
      }
    }
  }

  .map-right {
    position: relative;
    grid-column: 8 / span 6;
    background: var(--color-light-gray);
    height: 26rem;
    @media ${(props) => props.theme.breakpoints.m} {
      grid-column: 2 / span 6;
      height: 20rem;
      position: relative;
      z-index: 6;
    }
  }

  .corner-left {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 10rem;
    z-index: 0;
    @media ${(props) => props.theme.breakpoints.m} {
      height: 6rem;
    }
  }
`;

const PropertiesHomeSection = () => {
  const data = useStaticQuery(graphql`
    query mapPropertiesAndMapPoiAndPropertyMapHomeQuery {
      sanityHomePage {
        propertiesTitle
        propertiesDescription
      }
      allSanityProperties {
        nodes {
          offStreetParking
          property
          location {
            lat
            lng
          }
          _id
        }
      }
      allSanityPoi {
        nodes {
          poi
          poiImage {
            asset {
              url
            }
          }
          poiLocation {
            lat
            lng
          }
          _id
        }
      }
    }
  `);

  const { propertiesTitle, propertiesDescription } = data.sanityHomePage;

  const properties = data.allSanityProperties.nodes;
  const pois = data.allSanityPoi.nodes;

  return (
    <PropertiesHomeSectionStyles>
      <div className="text-left">
        <h2>{propertiesTitle}</h2>
        <p>{propertiesDescription}</p>
        <Link to="/properties" className="btn btn-secondary btn-white">
          View Properties
        </Link>
      </div>
      <div className="map-right">
        <GoogleMaps properties={properties} pois={pois} zoomLevel={15} />
      </div>
      <img src={cornerLeft} alt="corner left" className="corner-left" />
    </PropertiesHomeSectionStyles>
  );
};

export default PropertiesHomeSection;
