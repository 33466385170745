import React, { useState, useEffect } from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import TestimonialCard from "./TestimonialCard";
import Row from "./Layout/Row";
import useWindowWidth from "./hooks/useWindowWidth";
import { motion } from "framer-motion";
import ArrowLeft from "../assets/arrow-left.svg";
import ArrowRight from "../assets/arrow-right.svg";

const TestimonialsHomeSectionStyles = styled.section`
  grid-column: 2 / span 12;
  display: grid;
  grid-template-columns: repeat(12, minmax(auto, 5.2rem));
  gap: 0 2rem;
  margin: 0;
  padding: 4em 0;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 2 / span 6;
    grid-template-columns: repeat(6, 1fr);
    gap: 0 1rem;
    margin: 0;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    gap: 0 1rem;
    margin: 0;
  }

  .testimonials {
    overflow: hidden;
  }

  .testimonials-title {
    text-align: center;
  }
  .navigation {
    display: flex;
    justify-content: center;
    margin-top: 2em;
    button {
      cursor: pointer;
      background: none;
      border: none;
      width: 3em;
      height: 3em;
      margin-right: 1em;
      &:last-child {
        margin-right: 0;
      }
      &.disabled {
        opacity: 0.4;
        cursor: auto;
      }
    }
  }
`;

const TestimonialsHomeSection = () => {
  const [[mainSlide, direction], setMainSlide] = useState([1, 0]);
  const [slidesNo, setSlidesNo] = useState(3);

  const windowWidth = useWindowWidth();

  useEffect(() => {
    if (windowWidth > 994) {
      setSlidesNo(3);
      setMainSlide([1, 0]);
    } else {
      setSlidesNo(9);
      setMainSlide([1, 0]);
    }
  }, [windowWidth]);

  useEffect(() => {
    let interval = setInterval(
      () =>
        mainSlide < slidesNo
          ? paginate(1)
          : slidesNo === 9
          ? paginate(-9)
          : paginate(-2),
      6000
    );
    return () => {
      clearInterval(interval);
    };
  });

  const data = useStaticQuery(graphql`
    query TestimonialsQuery {
      sanityHomePage {
        testimonials {
          name
          testimonial
          personImage {
            asset {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 350
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
  `);

  const { testimonials } = data.sanityHomePage;

  const handleClickLeft = () => {
    if (mainSlide === 1) {
      return;
    } else {
      paginate(-1);
    }
  };

  const handleClickRight = () => {
    if (mainSlide === slidesNo) {
      return;
    } else {
      paginate(1);
    }
  };

  const zeroPad = (num, places) => String(num).padStart(places, "0");

  const paginate = (newDirection) => {
    setMainSlide([mainSlide + newDirection, newDirection]);
  };

  return (
    <TestimonialsHomeSectionStyles>
      <Row>
        <h2 className="testimonials-title">Student Testimonials</h2>
      </Row>
      <Row className="testimonials">
        <motion.div
          animate={{
            x:
              direction === 1 || direction === -1
                ? `-${mainSlide - 1}${zeroPad((mainSlide - 1) * 2, 2)}%`
                : 0,
          }}
          transition={{ ease: "easeInOut", duration: 0.7 }}
          className="testimonials-wrapper flex"
        >
          {testimonials.map((testimonial, i) => (
            <TestimonialCard
              key={i}
              name={testimonial.name}
              testimonial={testimonial.testimonial}
              image={
                testimonial.personImage.asset.localFile.childImageSharp
                  .gatsbyImageData
              }
            />
          ))}
        </motion.div>
        <div className="navigation">
          <button
            onClick={handleClickLeft}
            className={mainSlide === 1 ? "disabled" : ""}
          >
            <img src={ArrowLeft} alt="Arrow Left" />
          </button>
          <button
            onClick={handleClickRight}
            className={mainSlide === slidesNo ? "disabled" : ""}
          >
            <img src={ArrowRight} alt="Arrow Right" />
          </button>
        </div>
      </Row>
    </TestimonialsHomeSectionStyles>
  );
};

export default TestimonialsHomeSection;
