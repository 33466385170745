import React from "react";
import styled from "styled-components";
import Reason from "./Reason";
import Row from "./Layout/Row";
import MaxWidthSection from "./Layout/MaxWidthSection";
import CornerTL from "../assets/corner-tl-whyus.svg";
import { useStaticQuery, graphql } from "gatsby";

const WhyChooseUsHomeSectionStyles = styled.section`
  grid-column: 1 / span 14;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 5.2rem)) 1fr;
  gap: 0 2rem;
  margin: 0;
  padding: 4em 0;
  background: var(--color-accent);
  position: relative;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 1 / span 8;
    grid-template-columns: 2rem repeat(6, 1fr) 2rem;
    gap: 0 1rem;
    margin: 0;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    grid-template-columns: 1rem repeat(6, 1fr) 1rem;
    gap: 0 1rem;
    margin: 0;
  }

  h1,
  h2,
  h3,
  p {
    color: var(--color-white);
  }

  .whyus-title {
    text-align: center;
  }

  h1::-moz-selection,
  h2::-moz-selection,
  h3::-moz-selection,
  p::-moz-selection,
  img::-moz-selection {
    color: var(--color-white);
    background-color: var(--color-black);
  }
  h1::selection,
  h2::selection,
  h3::selection,
  p::selection,
  img::selection {
    color: var(--color-white);
    background-color: var(--color-black);
  }

  .corner-left {
    position: absolute;
    left: 0;
    top: 0;
    height: 10rem;
    z-index: 0;
    @media ${(props) => props.theme.breakpoints.m} {
      height: 6rem;
    }
  }
`;

const WhyChooseUsHomeSection = () => {
  const data = useStaticQuery(graphql`
    query WhyChooseUsQuery {
      sanityHomePage {
        whyChooseUs {
          title
          reasons {
            reason
            reasonTitle
            reasonIcon {
              asset {
                url
              }
            }
          }
        }
      }
    }
  `);

  const title = data.sanityHomePage.whyChooseUs.title;
  const reasons = data.sanityHomePage.whyChooseUs.reasons;

  return (
    <WhyChooseUsHomeSectionStyles>
      <MaxWidthSection>
        <Row>
          <h2 className="whyus-title">{title}</h2>
        </Row>
      </MaxWidthSection>
      <MaxWidthSection>
        {reasons.map((reason, i) => (
          <Reason
            key={i}
            reason={reason.reason}
            icon={reason.reasonIcon.asset.url}
            title={reason.reasonTitle}
          />
        ))}
      </MaxWidthSection>
      <img src={CornerTL} alt="Corner Left" className="corner-left" />
    </WhyChooseUsHomeSectionStyles>
  );
};

export default WhyChooseUsHomeSection;
