import React from "react";
import styled from "styled-components";

const ReasonStyle = styled.div`
  grid-column-end: span 3;
  text-align: center;
  position: relative;
  z-index: 1;
  @media ${(props) => props.theme.breakpoints.m} {
    grid-column-end: span 6;
    margin-bottom: 2em;
  }

  img {
    width: 7rem;
    margin-bottom: 1em;
  }
`;

const Reason = ({ reason, icon, title }) => {
  return (
    <ReasonStyle className="reasonCard">
      <img src={icon} alt={title} />
      <h3>{title}</h3>
      <p>{reason}</p>
    </ReasonStyle>
  );
};

export default Reason;
