import React from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import ContactFormHome from "./ContactFormHome";

const ContactHomeSectionStyles = styled.section`
  grid-column: 2 / span 12;
  display: grid;
  grid-template-columns: repeat(12, minmax(auto, 5.2rem));
  gap: 0 2rem;
  margin: 0;
  padding: 2em 0;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 2 / span 6;
    grid-template-columns: repeat(6, 1fr);
    gap: 0 1rem;
    margin: 0;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    gap: 0 1rem;
    margin: 0;
  }

  .gatsby-image-wrapper {
    grid-column: 1 / span 6;
    @media ${(props) => props.theme.breakpoints.m} {
      margin-bottom: 2em;
    }
  }
`;

const ContactHomeSection = () => {
  const data = useStaticQuery(graphql`
    query ContactHomeQuery {
      sanityHomePage {
        contactSection {
          title
          image {
            asset {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 700
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
  `);

  const image =
    data.sanityHomePage.contactSection.image.asset.localFile.childImageSharp
      .gatsbyImageData;
  const title = data.sanityHomePage.contactSection.title;

  return (
    <ContactHomeSectionStyles>
      <GatsbyImage image={image} alt="Students" />
      <ContactFormHome title={title} />
    </ContactHomeSectionStyles>
  );
};

export default ContactHomeSection;
