import { Link, useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import styled from "styled-components";
import bannerBackground from "../assets/banner-background.svg";

const HeroBannerStyles = styled.div`
  position: relative;
  grid-column: 1 / span 14;
  height: 100vh;
  grid-row: 1/2;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 5.2rem)) 1fr;
  gap: 0 2rem;
  margin: 0;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 1 / span 8;
    grid-template-columns: 2rem repeat(6, 1fr) 2rem;
    gap: 0 1rem;
    margin: 0;
    height: auto;
    grid-row: 2;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    grid-template-columns: 1rem repeat(6, 1fr) 1rem;
    gap: 0 1rem;
    margin: 0;
  }

  .text-left {
    grid-column: 2 / span 6;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h1 {
      font-size: var(--font-hero-size);
      line-height: var(--font-hero-line-height);
      margin-bottom: 0.3em;
      @media ${(props) => props.theme.breakpoints.m} {
        font-size: var(--font-herophone-size);
        line-height: var(--font-herophone-line-height);
        text-align: center;
      }
    }

    p {
      font-size: var(--font-title4-size);
      line-height: var(--font-title4-line-height);
      padding-right: 5em;
      @media ${(props) => props.theme.breakpoints.m} {
        font-size: var(--font-base-size);
        line-height: var(--font-base-line-height);
        padding-right: 0;
        margin-bottom: 1em;
        text-align: center;
      }
    }

    @media (max-height: 800px) {
      h1 {
        font-size: var(--font-herotablet-size);
        line-height: var(--font-herotablet-line-height);
      }

      p {
        font-size: var(--font-large-size);
        line-height: var(--font-title4-line-height);
      }
    }

    .buttons {
      display: flex;
      @media ${(props) => props.theme.breakpoints.m} {
        flex-direction: column;
      }
      .btn {
        margin-right: 1em;
        margin-top: 5em;
        @media ${(props) => props.theme.breakpoints.m} {
          margin-right: 0;
          margin-top: 0;
          margin-bottom: 1em;
          text-align: center;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .image-right {
    grid-column: 8 / span 6;
    display: flex;
    align-items: center;
    overflow: hidden;
    @media (max-height: 800px) {
      .gatsby-image-wrapper {
        height: 62%;
      }
    }
    @media ${(props) => props.theme.breakpoints.m} {
      grid-column: 2 / span 6;
      .gatsby-image-wrapper {
        height: 80%;
      }
    }
  }

  .banner-bg {
    position: absolute;
    z-index: -1;
    height: 100%;
    right: 0;
    @media ${(props) => props.theme.breakpoints.m} {
      bottom: -1em;
      height: auto;
      width: 80%;
    }
  }
`;

const HeroBanner = () => {
  const data = useStaticQuery(graphql`
    query HeroBannerQuery {
      sanityHomePage {
        heroBanner {
          title
          description
          image {
            asset {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 700
                    placeholder: NONE
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
  `);

  const { title, description } = data.sanityHomePage.heroBanner;
  const heroBannerImage =
    data.sanityHomePage.heroBanner.image.asset.localFile.childImageSharp
      .gatsbyImageData;

  return (
    <HeroBannerStyles>
      <div className="text-left">
        <h1>{title}</h1>
        <p>{description}</p>
        <div className="buttons">
          <Link to="/contact-us" className="btn btn-primary">
            Schedule a Showing
          </Link>
          <a
            href="https://jandjip.managebuilding.com/Resident/portal/login"
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-secondary"
          >
            Log In
          </a>
        </div>
      </div>
      <div className="image-right">
        <GatsbyImage
          image={heroBannerImage}
          alt="Students off campus"
          loading="eager"
        />
      </div>
      <img
        src={bannerBackground}
        alt="Banner background"
        className="banner-bg"
      />
    </HeroBannerStyles>
  );
};

export default HeroBanner;
