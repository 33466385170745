import * as React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import HeroBanner from "../components/HeroBanner";
import BannerCtaHomeSection from "../components/BannerCtaHomeSection";
import PropertiesHomeSection from "../components/PropertiesHomeSection";
import TestimonialsHomeSection from "../components/TestimonialsHomeSection";
import WhyChooseUsHomeSection from "../components/WhyChooseUsHomeSection";
import ContactHomeSection from "../components/ContactHomeSection";
import FeaturedNews from "../components/FeaturedNews";

const HomepageStyles = styled.main`
  grid-column: 1 / span 14;
  display: grid;
  grid-template-columns: 1fr repeat(12, minmax(auto, 5.2rem)) 1fr;
  gap: 0 2rem;
  margin: 0;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 1 / span 8;
    grid-template-columns: 2rem repeat(6, 1fr) 2rem;
    gap: 0 1rem;
    margin: 0;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    grid-template-columns: 1rem repeat(6, 1fr) 1rem;
    gap: 0 1rem;
    margin: 0;
  }
`;

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="J&J Investment Properties" />
      <HeroBanner />
      <HomepageStyles>
        <BannerCtaHomeSection />
        <PropertiesHomeSection />
        <TestimonialsHomeSection />
        <FeaturedNews />
        <WhyChooseUsHomeSection />
        <ContactHomeSection />
      </HomepageStyles>
    </Layout>
  );
};

export default IndexPage;
