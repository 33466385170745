import React, { useState, useRef } from "react";
import styled from "styled-components";
import { navigate } from "gatsby";

const ContactFormHomeStyles = styled.div`
  grid-column: 7 / span 6;
  display: flex;
  align-content: center;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 1 / span 6;
    text-align: center;
  }

  button {
    border: none;
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    form {
      width: 100%;

      input,
      textarea {
        position: relative;
        display: inline-block;
        width: 100%;
      }

      .field {
        position: relative;
        margin-bottom: 1.6em;
        &.no-margin {
          margin-bottom: 0;
        }
      }

      textarea {
        height: 6em;
        resize: vertical;
        max-height: 240px;
        min-height: 70px;
      }

      label span {
        position: absolute;
        display: inline-block;
        margin: 0 auto;
        padding: 5px 0 5px 5px;
        top: 0;
        z-index: 1;
        transition: all 0.5s ease-in-out;
        color: var(--color-gray);
      }

      input {
        height: 38px;
      }

      input[type="text"],
      input[type="email"],
      input[type="tel"],
      textarea,
      input[type="password"] {
        font-size: 1em;
        padding: 5px 0 5px 5px;
        margin-bottom: 0.5em;
        z-index: 6;
        background: transparent;
        border: none;
        border-bottom: 1px solid var(--color-black);
      }

      .filled {
        top: -28px;
        font-size: 0.9em;
      }

      .hidden {
        display: none;
      }

      input:focus,
      select:focus,
      textarea:focus,
      button:focus {
        outline-color: var(--color-accent);
      }
    }
  }
`;

const ContactFormHome = ({ title }) => {
  const [nameFocused, setNameFocused] = useState(false);
  const [emailFocused, setEmailFocused] = useState(false);
  const [phoneFocused, setPhoneFocused] = useState(false);
  const [messageFocused, setMessageFocused] = useState(false);

  const [nameValue, setNameValue] = useState(null);
  const [emailValue, setEmailValue] = useState(null);
  const [phoneValue, setPhoneValue] = useState(null);
  const [messageValue, setMessageValue] = useState(null);

  const [formFields, setFormFields] = useState({});

  const onNameFocus = () => setNameFocused(true);
  const onNameBlur = () => setNameFocused(false);

  const onEmailFocus = () => setEmailFocused(true);
  const onEmailBlur = () => setEmailFocused(false);

  const onPhoneFocus = () => setPhoneFocused(true);
  const onPhoneBlur = () => setPhoneFocused(false);

  const onMessageFocus = () => setMessageFocused(true);
  const onMessageBlur = () => setMessageFocused(false);

  const nameField = useRef();

  const handleChange = (e) => {
    const el = e.target;
    if (el.name === "name") {
      setNameValue(el.value);
    } else if (el.name === "email") {
      setEmailValue(el.value);
    } else if (el.name === "phone") {
      setPhoneValue(el.value);
    } else if (el.name === "message") {
      setMessageValue(el.value);
    }
    setFormFields({ ...formFields, [el.name]: el.value });
  };

  const encode = (data) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key])
      )
      .join("&");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const el = e.target;

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": el.getAttribute("name"),
        ...formFields,
      }),
    })
      .then(() => navigate("/thank-you/"))
      .catch((error) => alert(error));
  };

  return (
    <ContactFormHomeStyles>
      <div className="form-wrapper">
        <h2>{title}</h2>
        <form
          className="contact-form-main"
          name="contact-ajax-homepage"
          method="POST"
          data-netlify="true"
          netlify-honeypot="bot-field"
          onSubmit={handleSubmit}
        >
          <div className="field">
            <label>
              <span className={nameFocused || nameValue ? "filled" : ""}>
                Name:
              </span>
              <input
                required
                type="text"
                name="name"
                ref={nameField}
                onFocus={onNameFocus}
                onBlur={onNameBlur}
                onChange={handleChange}
              />
            </label>
          </div>

          <div className="field">
            <label>
              <span className={emailFocused || emailValue ? "filled" : ""}>
                Email:
              </span>
              <input
                required
                type="email"
                name="email"
                onFocus={onEmailFocus}
                onBlur={onEmailBlur}
                onChange={handleChange}
              />
            </label>
          </div>

          <div className="field">
            <label>
              <span className={phoneFocused || phoneValue ? "filled" : ""}>
                Phone:
              </span>
              <input
                required
                type="tel"
                name="phone"
                onFocus={onPhoneFocus}
                onBlur={onPhoneBlur}
                onChange={handleChange}
              />
            </label>
          </div>

          <div className="field no-margin">
            <label>
              <span className={messageFocused || messageValue ? "filled" : ""}>
                Message:
              </span>
              <textarea
                required
                name="message"
                onFocus={onMessageFocus}
                onBlur={onMessageBlur}
                onChange={handleChange}
              ></textarea>
            </label>
          </div>

          <p className="hidden">
            <label>
              Don’t fill this out if you’re human: <input name="bot-field" />
            </label>
          </p>

          <button type="submit" className="btn btn-primary btn-wide">
            Submit
          </button>
        </form>
      </div>
    </ContactFormHomeStyles>
  );
};

export default ContactFormHome;
