import React, { useState, useEffect } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import { motion, AnimatePresence } from "framer-motion";

const BannerCtaHomeSectionStyles = styled.section`
  grid-column: 2 / span 12;
  display: grid;
  grid-template-columns: repeat(12, minmax(auto, 5.2rem));
  gap: 0 2rem;
  margin: 0;
  padding: 4em 0;

  @media ${(props) => props.theme.breakpoints.m} {
    grid-column: 2 / span 6;
    grid-template-columns: repeat(6, 1fr);
    gap: 0 1rem;
    margin: 0;
  }

  @media ${(props) => props.theme.breakpoints.s} {
    gap: 0 1rem;
    margin: 0;
  }

  .banner-left {
    grid-column: 1 / span 3;
    @media ${(props) => props.theme.breakpoints.m} {
      grid-column: 1 / span 6;
      margin-bottom: 1em;
    }
  }

  .banner-center {
    grid-column: 4 / span 3;
    @media ${(props) => props.theme.breakpoints.m} {
      display: none;
    }
  }

  .banner-right {
    grid-column: 7 / span 2;
    @media ${(props) => props.theme.breakpoints.m} {
      display: none;
    }
  }

  .banner-left,
  .banner-right,
  .banner-center {
    overflow: hidden;
    div {
      height: 100%;
      .gatsby-image-wrapper {
        width: 100%;
      }
    }
  }

  .text-right {
    grid-column: 9 / span 4;
    @media ${(props) => props.theme.breakpoints.m} {
      grid-column: 1 / span 6;
      text-align: center;
    }
  }
`;

const BannerCtaHomeSection = () => {
  const data = useStaticQuery(graphql`
    query CtaBannerQuery {
      sanityHomePage {
        ctaBanner {
          title
          description
          firstSlider {
            asset {
              localFile {
                childImageSharp {
                  id
                  gatsbyImageData(
                    width: 400
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
          secondSlider {
            asset {
              localFile {
                childImageSharp {
                  id
                  gatsbyImageData(
                    width: 400
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
          thirdSlider {
            asset {
              localFile {
                childImageSharp {
                  id
                  gatsbyImageData(
                    width: 400
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
          }
        }
      }
    }
  `);

  const [currentSlide, setCurrentSlide] = useState(0);
  const maxSlides = 2;

  const ctaTitle = data.sanityHomePage.ctaBanner.title;
  const ctaDescription = data.sanityHomePage.ctaBanner.description;

  const firstSlider = data.sanityHomePage.ctaBanner.firstSlider;
  const secondSlider = data.sanityHomePage.ctaBanner.secondSlider;
  const thirdSlider = data.sanityHomePage.ctaBanner.thirdSlider;

  const variants = {
    enter: {
      x: "100%",
      opacity: 0,
    },

    exit: {
      x: "-100%",
      opacity: 0,
    },
  };

  useEffect(() => {
    let interval = setInterval(
      () =>
        currentSlide < maxSlides
          ? setCurrentSlide(currentSlide + 1)
          : setCurrentSlide(0),
      4000
    );
    return () => {
      clearInterval(interval);
    };
  });

  return (
    <BannerCtaHomeSectionStyles id="banner-cta">
      <div className="banner-left">
        <AnimatePresence initial={false} exitBeforeEnter>
          {firstSlider.map(
            (slide, i) =>
              i === currentSlide && (
                <motion.div
                  variants={variants}
                  initial="enter"
                  animate={{ x: 0, opacity: 1 }}
                  exit="exit"
                  transition={{
                    x: { type: "spring", stiffness: 150, damping: 25 },
                    opacity: { duration: 0.6 },
                  }}
                  className="image-wrapper"
                  key={slide.asset.localFile.childImageSharp.id}
                >
                  <GatsbyImage
                    image={
                      slide.asset.localFile.childImageSharp.gatsbyImageData
                    }
                    alt="Property"
                    loading="eager"
                  />
                </motion.div>
              )
          )}
        </AnimatePresence>
      </div>
      <div className="banner-center">
        <AnimatePresence initial={false} exitBeforeEnter>
          {secondSlider.map(
            (slide, i) =>
              i === currentSlide && (
                <motion.div
                  variants={variants}
                  initial="enter"
                  animate={{ x: 0, opacity: 1 }}
                  exit="exit"
                  transition={{
                    x: { type: "spring", stiffness: 150, damping: 30 },
                    opacity: { duration: 0.8 },
                  }}
                  className="image-wrapper"
                  key={slide.asset.localFile.childImageSharp.id}
                >
                  <GatsbyImage
                    image={
                      slide.asset.localFile.childImageSharp.gatsbyImageData
                    }
                    alt="Property"
                    loading="eager"
                  />
                </motion.div>
              )
          )}
        </AnimatePresence>
      </div>
      <div className="banner-right">
        <AnimatePresence initial={false} exitBeforeEnter>
          {thirdSlider.map(
            (slide, i) =>
              i === currentSlide && (
                <motion.div
                  variants={variants}
                  initial="enter"
                  animate={{ x: 0, opacity: 1 }}
                  exit="exit"
                  transition={{
                    x: { type: "spring", stiffness: 150, damping: 35 },
                    opacity: { duration: 1 },
                  }}
                  className="image-wrapper"
                  key={slide.asset.localFile.childImageSharp.id}
                >
                  <GatsbyImage
                    image={
                      slide.asset.localFile.childImageSharp.gatsbyImageData
                    }
                    alt="Property"
                    loading="eager"
                  />
                </motion.div>
              )
          )}
        </AnimatePresence>
      </div>
      <div className="text-right">
        <h2>{ctaTitle}</h2>
        <p>{ctaDescription}</p>
        <Link to="/contact-us" className="btn btn-primary">
          Schedule a Showing
        </Link>
      </div>
    </BannerCtaHomeSectionStyles>
  );
};

export default BannerCtaHomeSection;
