import React from "react";
import styled from "styled-components";
import { GatsbyImage } from "gatsby-plugin-image";
import CornerTL from "../assets/corner-tl-testimonials.svg";

const TestimonialCardStyles = styled.div`
  min-width: 32%;
  margin-right: 2%;
  padding: 2rem 2rem;
  text-align: center;
  background: var(--color-very-light-gray);
  position: relative;

  display: flex;
  flex-direction: column;

  align-items: center;

  @media ${(props) => props.theme.breakpoints.m} {
    min-width: 100%;
  }

  .card-text {
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  p {
    margin-top: 2rem;
    color: var(--color-black);
  }

  .gatsby-image-wrapper {
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
  }

  .corner-tl {
    position: absolute;
    z-index: 0;
    height: 6rem;
    top: 0;
    left: 0;
  }
`;

const TestimonialsCard = ({ name, testimonial, image }) => {
  return (
    <TestimonialCardStyles>
      <GatsbyImage image={image} alt={name} />
      <div className="card-text">
        <p>{testimonial}</p>
        <p>
          <b>{name}</b>
        </p>
      </div>
      <img
        src={CornerTL}
        alt="corner top left testimonial"
        className="corner-tl"
      />
    </TestimonialCardStyles>
  );
};

export default TestimonialsCard;
